
// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

/*
 * @file
 * global.styles
 */

html {
  background: #fff;
}

body {
  color: $text_color;
  &.sliding-popup-processed{
    padding-bottom:50px;
  }
}

/* =============================================================================
 *   Wrappers
 * ========================================================================== */

.page{
  background:$page_background;
  margin:0 auto;
}

.page-header {
  @include horizontal-gradient($grey_brown, #fff);
  box-shadow:0 3px 4px rgba(0,0,0,0.25);
  > .inner{
    margin:0 auto;
    padding:$grid_vertical $grid 5px $grid;
    max-width:$page_max_width;
  }
}

.page-columns {}

.page-content-column {}

.page-content {}

.page-footer {
  @include horizontal-gradient($grey_brown_dark, (lighten($grey_brown_dark, 8%)));
  padding:$grid_vertical ($grid * 3);
  position:relative;
  margin-top:15px;
  overflow:hidden;
  a{
    color:$highlight_text_color;
  }
  .pane-title{
    font-size:$font_normal;
    font-weight:$font_weight_normal;
    border-bottom: 1px solid white;
    box-shadow:0 1px 0 $grey_dark;
    a{
      display:block;
      padding-bottom:15px;
      &::before{
        display:none;
      }
    }
  }
  .menu{
    font-size:$font_small;
  }
  &.expandable-footer:not(.open){
    .region-footer{
      display:none;
    }
  }
  .expandable-footer__opener{
    position:absolute;
    left:0;
    top:0;
    cursor:pointer;
    height:20px;
    width:100%;
    text-indent:-9000px;
    &::before{
      font-family:$iconfont;
      @include get-ico-only('arrow-thin-top');
      color:white;
      font-size:30px;
      text-indent:0;
      float:right;
      text-align:center;
      width:100%;
      height:20px;
      line-height:20px;
    }
    &:hover{
      &::before{
        color:$highlight_sec;
      }
    }
  }
  &.open{
    .expandable-footer__opener{
      &::before{
        @include get-ico-only('arrow-thin-bottom');
      }
    }

  }
}

/* =============================================================================
 *   Branding
 * ========================================================================== */

/*
 * Wraps all the branding elements, logo, name and slogan
 */
.page-branding {

}

/*
 * Logo
 */
.page-logo {
  padding: 0;
  text-align:center;
  @media only screen and (min-width: $tablet_landscape_start){
    float:right;
  }
  a{
    display:block;
  }
  img {
    vertical-align: bottom;
  }
}
#page-logo__image{
  pointer-events: none;
}
#header-logo{
  pointer-events:none;
}
.page-print-logo{
  // hide print logo on screen devices
  display:none;
}

/*
 * Site name (h1)
 */
.page-name {
  margin: 0;
}

/*
 * Site slogan (h2)
 */
.page-slogan {
  display:none;
}

/* Title */
.front .page-title,
.front .pane-node-body .pane-title{
  display:none;
}

@media only screen and (min-width: $tablet_landscape_start){
  .page-logo{
    text-align:left;
    margin:0;
    line-height:0;
  }
  .page-logo__img{

  }
  .page-logo-img--svg{
    pointer-events: none;
  }
  .page-logo-img__fallback{

  }
}

/* =============================================================================
 *   Animations (CSS)
 * ========================================================================== */

// Always fade colors
*,
*::before,
*::after{
  transition-property: color, background-color, border-color;
  transition-duration: 300ms;
}

// Pulsating Glow
@include keyframes('pulsating-glow') {
  0% { box-shadow:0 0 3px $red; }
  50% { box-shadow:0 0 10px $red; }
  100% { box-shadow:0 0 3px $red; }
}

/* Dragging complete (eg. Sticky Tables) */
@-webkit-keyframes dragging-complete {
  0%   { background:#f5ffea; }
  100% { background:#fff; }
}
@-moz-keyframes dragging-complete {
  0%   { background:#f5ffea; }
  100% { background:#fff; }
}
@-o-keyframes dragging-complete {
  0%   { background:#f5ffea; }
  100% { background:#fff; }
}
@keyframes dragging-complete {
  0%   { background:#f5ffea; }
  100% { background:#fff; }
}

/* Swipe In (eg. mobile menu trail) */
@-webkit-keyframes swipe-in {
  0%   {
    -webkit-transform:translate(-150px, 0);
  }
  100% {
    -webkit-transform:translate(0, 0);
  }
}
@-moz-keyframes swipe-in {
  0%   {
    -moz-transform:translate(-150px, 0);
  }
  100% {
    -moz-transform:translate(0, 0);
  }
}
@-o-keyframes swipe-in {
  0%   {
    -o-transform:translate(-150px, 0);
  }
  100% {
    -o-transform:translate(0, 0);
  }
}
@keyframes swipe-in {
  0%   {
    transform:translate(-150px, 0);
  }
  100% {
    transform:translate(0, 0);
  }
}

/* =============================================================================
 *   Regions & Panel Regions
 * ========================================================================== */

.region {}

.region-inner {}

.region-header {
  display:none; // hide on small devices
  margin:0;
  font-size:17px;
  padding-top:35px;
  color:white;
  font-weight:$font_weight_normal;
  text-shadow:0 0 5px $grey_brown;
  .menu{
    @include horizontal-menu();
    @include tiny-menu('horizontal');
  }
}

.region-header-slide{ clear:both; }

.region-navigation-bar{
  clear:both;
  background:$page_background;
  margin-bottom:$grid_vertical;
  margin-top:$grid_vertical;
  > .region-inner{
    max-width:$page_max_width;
    margin:0 auto;
  }
}

.region-help {
  .block{
    @include box-style('info');
  }
}

.region-footer{
  .menu{
    li{
      a{
        padding:5px 0;
      }
    }
  }
}

.region-footer-bottom {
  text-align:right;
  .menu{
    @include tiny-menu('horizontal');
  }
}

/* Panel Regions (Page Panels default regions) */
/* "Header" */
.page-panel-head{
  margin-bottom:$grid_vertical;
}

/* "Sidebar Right" & "Sidebar Left" default block style */
.page-panel-sidebar-first,
.page-panel-sidebar-second,
.quicklinks-block-append-region,
.page-layout-sideblock-top{
  .pane-title{
    font-size: 18px;
    color:$text_color;
    margin-top: 0px;
    margin-bottom: 8px;
    border-bottom: 1px solid $grey;
    padding: 0 $grid 8px $grid;
    line-height: 1.1em;
    a{
      color:$highlight;
    }
  }
  .panel-pane{
    background:white;
    box-shadow:$shadow;
    margin-bottom:$grid_vertical;
    padding:15px 0px;
  }
  .pane-content{
    padding:0 $grid;
    *:first-child{
  /*  margin-top:0;
      padding-top:0;*/
    }
  }
}


/* "Sidebar Left" */
.page-panel-sidebar-first{
  .pane-menu-tree,
  .pane-menu-block{
    background:$grey;
    border:1px solid white;
    box-shadow:$shadow;
    margin-bottom:$grid_vertical;
    padding:$grid_vertical 0;
    .pane-title{
      font-size: 18px;
      color:$highlight;
      margin-top: 0px;
      margin-bottom: 8px;
      border-bottom: 1px solid white;
      box-shadow:0px 1px 0px #C0C0C0;
      padding: 0 $grid 8px $grid;
      line-height: 1.1em;
      a{
        color:$highlight;
      }
    }
    .pane-content{
      line-height:$less_line_height;
      padding:0 $grid;
      h2:first-child,
      h3:first-child,
      p:first-child{
        margin-top:0;
        padding-top:0;
      }
    }
  }
  // Fields left
  .pane-field-group{
    font-size:$font_medium;
    .field{
      margin-bottom:8px;
    }
  }

  // Contents (Some default modifications)
  h3,
  h4{
    font-size:$font_normal;
    line-height:1.1em;
    margin-bottom:8px;
  }
  .btn{
    margin-bottom:3px;
    text-align:left;
  }
  .btn--big{
    padding-left:$grid !important;
    padding-right:$grid !important;
  }
  .view .item-list .views-row{
    margin-bottom:8px;
  }
  // Menus
  .menu{
    font-size:$font_medium;
    li{
      a{
        padding-left:0;
        padding-right:0;
        transition-property: all;
        &:hover{
          background-color:$highlight_sec;
          color:$highlight_text_color;
          padding-left:5px;
          padding-right:5px;
        }
      }
    }
  }
}

/* Content Regions */
.page-panel-content{
  > .region-inner{
    padding:$grid_vertical $grid;
    background:white;
    box-shadow:$shadow;
    margin-bottom:$grid_vertical;
  }
}
.page-layout-sideblock-top{
  > .region-inner{
/*    padding:$grid_vertical $grid;
    background:white;*/
    min-height:233px;
/*    box-shadow:$shadow;*/
  }
  .pane-title{
/*    margin-top:0;*/
  }
  .panel-pane{
    min-height:233px;
    margin-bottom:0 !important;
  }
}

/* =============================================================================
 *   Block Styling
 * ========================================================================== */

.block {

}
.block-inner {

}
.block-title {
  margin-top: 0;
}
.block-content {
  p:last-child{ margin-bottom:0; }
}

/* jQuery appended Blocks (moved to top on small devices) */
.block-content > .panel-pane,
.block-content > .block{
  margin-bottom:$grid_vertical;
}

/* Block Specific */
/* -- Search */
.region-header .block-search{
  margin-bottom:0;
  margin-top:15px;
}

/* -- Header Image */
.pane-header-slideshow,
.block-views-header-slideshow-block{
  line-height:0;
  .views-row{
    padding:0;
    margin:0;
  }
}
.view-header-slideshow .view-empty{
  padding:0;
  border:0 none;
  border-radius:0;
}
/* -- -- Header Image BLOCK "Matching Images" (node edit) */
.header-image--matching-images{
  @include box-style;
}

/* Erfahrung - Kompetenz - Vertrauen */
.block-block-5{
  color:$highlight;
  text-align:center;
  text-transform:uppercase;
  margin:15px 0;
  .block-content{
    @include clearfix-after;
  }
  ul{
    margin:0;
    padding:15px 0;
    list-style:none;
    line-height:1.35em;
    font-size:25px;
    letter-spacing:9px;
    text-align:center;
    li{
      margin-bottom:22px;
      @media only screen and (min-width: $desktop_floating_breakpoint){
        padding:0;
        margin:0;
        display:inline-block;
        position:relative;
        padding-right:8px;
        &:not(:last-child)::after{
          content:"\00B7";
          font-weight:$font_weight_bold;
          color:$highlight_sec;
          font-size:21px;
          // line-height:26px;
          vertical-align:top;
          margin-left:15px;
        }
      }
    }
  }
  img{
    margin-top:8px;
    margin-bottom:0;
    @media only screen and (min-width: $desktop_floating_breakpoint){
      margin-top:-14px;
    }
    &.right{
      margin-top:3px;
    }
  }
}

/* Tagcloud */
.block-views-ip-faq-tagcloud-block-1{
  canvas{
    width:100%;
    height:auto;
  }
}
#tagcloud{
  width:100%;
  max-width:500px;
}

/* Stellenangebote */
.view-ip-stellenangebote-block{
  color:$highlight_sec;
  line-height:1.1em;
  .views-row{
    margin:0;
  }
  a{
    color:$highlight_sec;
  }
  h3{
    font-size:$font_normal;
    margin-top:0;
  }
  .views-field-field-stelle-zu-besetzen-ab{
    float:right;
  }
}
/* -- Zurueck Schaltflaeche (special case - no sidebar) */
.region-two-cols-embed-embed{
  .panel-pane{
    @media #{$large-up}{
      float:right;
      margin-left:$thin_grid;
    }
  }
}


/* Submenu Tree */
/*.pane-menu-tree{
  .pane-title{
    a{
      @include ico('arrow-left');
    }
  }
}*/

/* History Back Block */
.block--history-back{
  .btn{
    margin-bottom:0;
  }
}

/* =============================================================================
 *  Field Styling (Output)
 * ========================================================================== */

.field {}
.field-label {
  font-size: 1em;
  font-weight: 700;
  font-family: inherit;
  line-height: inherit;
  margin-bottom: 0;
}

/* Basic Field Types */
/* -- Body */
.field-name-body{
  @include clearfix-after;
}
/* -- Image */
.field-type-image {
  a:not([target="_blank"]){
    cursor:zoom-in;
  }
  .field-item{
    // text-align:center;
  }
}
a.lightbox-processed{ cursor: -webkit-zoom-in; cursor: -moz-zoom-in; }

/* Named (custom) Fields */
/* -- Body */
.field-name-body{
  margin-bottom:2em;
}

/* -- Downloads Anhaenge */
.field-name-field-attachments{
  @include box-style;
  margin-bottom:1.5em;
  .file{
    display:block;
    border-bottom:1px solid $grey;
    border-right:1px solid $grey;
    padding:4px 0;
    margin-bottom:3px;
    @media only screen and (min-width: $tablet_portrait_end) {
      float:left;
      width:47%;
      margin-right:2%;
    }
    a{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow:hidden;
      display:block;
    }
  }
}

/* Adressen */
.views-field-field-anschrift{
  line-height:1.1em;
}

/* Bewerberformulare (Allg.) */
@mixin inline-fields{
  // Show Checkbox & Radio Fields side by side
  .form-item .form-item{
    display:inline-block;
    margin-right:12px;
    vertical-align:top;
    padding:0;
    line-height:1em;
  }
}

.field-name-field-geschaeftsstelle,
.field-name-field-panfr-geschaeftsstelle,
.field-name-field-anrede,
.field-name-field-panfr-ansprechpartner,
.field-name-field-panfr-ausbildung-noetig,
.field-name-field-panfr-erfahrung-noetig,
.field-name-field-panfr-arbeitszeit,
.field-name-field-panfr-schichtarbeit,
.field-name-field-panfr-eignungsuntersuchung,
.field-name-field-panfr-arbeitsmed-vorsorge,
.field-name-field-panfr-schutzausruestung,
.field-name-field-erfahrung-zeitarbeitsu,
.field-name-field-arbeitszeit,
.field-name-field-auftragsart,
.field-name-field-erfahrung-mit-zeitarbeitsu,
.field-name-field-berufsausbildung-abgeschlo,
.field-name-field-studium-abgeschlossen
{
  @include inline-fields;
}

/* Terms and Conditions / Datenschutz */
.field-name-field-panfr-datenschutz{
  @include box-style;
  .form-checkbox{
    border:2px solid $highlight;
  }
}

/* Hinweis */
.field-name-field-absenden-detailangaben{
  @include box-style('info');
}

/* Dateianhaenge */
.field-name-field-panfr-dateianhaenge{
  .fieldset-legend{
    @include ico('file');
  }
}

/* Personal anfordern Formular */
.group-firmendaten{
  > h3{
    span{
      @include ico('office');
    }
  }
}
.group-stellenangaben{
  > h3{
    span{
      @include ico('user');
    }
  }
}

/* Online Bewerbung Formular */
.group-kontaktdaten{
  > h3{
    span{
      @include ico('user');
    }
  }
}
.group-unterlagen{
  > h3{
    span{
      @include ico('file');
    }
  }
}

/* =============================================================================
 *   Search (Page)
 * ========================================================================== */

.custom-search-filter{
  ul{
    list-style:none;
    margin:0;
    padding:0;
  }
  li{
    list-style:none;
    margin:0;
    paddding:0;
    display:inline-block;
  }
  a{
    @include btn('ghost');
  }
}

/* =============================================================================
 *   Node Styling
 * ========================================================================== */

.node {
  margin-bottom: 20px;
}

.node .node-title {
  margin: 0;
}

/* -- Author Info */
footer.submitted{
  background: none repeat scroll 0 0 #F3F3F3;
  clear: both;
  color: #6F6F6F;
  margin-top: 1em;
  padding: 10px 10px 10px 15px;
  vertical-align: top;
  .user-picture {
    border: 5px solid #F3F3F3;
    background:#fff;
    float: left;
    line-height: 0;
    margin-right:15px;
    margin-left: -15px;
    margin-top: -10px;
  }
}

/* Personal online Anfragen (fuer Unternehmen) */
.entitytype-personalanfrage-form{
 /* .group-firmendaten{
    // #webksde-tf: Auf Wunsch des Kunden nur noch einspaltig.
    @media only screen and (min-width: $tablet_landscape_start){
      float:left;
      width:50%;
      padding-right:2%;
      border-right:1px solid $grey_light;
      margin-right:-1px;
    }
  }
  .group-stellenangaben{
    @media only screen and (min-width: $tablet_landscape_start){
      float:left;
      width:50%;
      padding-left:2%;
      border-left:1px solid $grey_light;
    }
  }*/
}
.pane--vorabberatung{
  text-align:right;
  strong{
    margin-right:$grid;
  }
}

/* Standort */
.node-type-geschaeftsstelle{
  .field{
    margin-bottom:5px;
  }
  .field-name-field-telefon{
/*    display:inline-block;
    background:$highlight_sec;
    color:$highlight_text_color;
    padding:10px 15px;*/
    .field-label{
      @include ico('phone');
    }
  }
  .field-name-field-email{
    .field-label{
      @include ico('mail');
    }
  }
  .field-name-field-fax{
    .field-label{
      @include ico('print');
    }
  }
  .pane-node-field-oeffnungszeiten{
    @media #{$large-up}{
      width:40%;
    }
  }
  // Anfahrt
  .pane-node-field-directions-map-image,
  .pane-ip-standorte-panel-pane-4{
    .gmap-gmap{
      height:200px !important;
    }
    @media #{$large-up}{
      float:left;
      width:60%;
      padding-right:$grid;
    }
  }
  .pane--anfahrt-button{
    @media #{$large-up}{
      padding-top:$grid_vertical;
      float:left;
      width:40%;
    }
    + .panel-pane{
      // clear the following pane
      clear:left;
    }
    .pane-content{
      > a{
        // Style directions link field as button
        @include btn;
        margin-top:1rem;
        &::before{
          content: "v";
          font-family: "iconfont";
          margin-right: 4px;
          font-weight: normal !important;
          font-style: normal !important;
          line-height:1;
          font-size:15px;
        }
      }
    }
  }
}
.views-field-field-directions-link a{
  // Style directions link field as button
  @include btn;
  &::before{
    content: "v";
    font-family: "iconfont";
    margin-right: 4px;
    font-weight: normal !important;
    font-style: normal !important;
    line-height:1;
    font-size:15px;
  }
}

.group-kontakt{
  legend{
    display:none;
  }
}
/* -- Oeffnungszeiten */
.pane-node-field-oeffnungszeiten{
  text-align:left !important;
  margin-bottom:$grid_vertical;
  .pane-title{
    margin-bottom:8px;
  }
}

/* -- Oeffnungszeit Status */
.oh-current-open:not(.element-hidden),
.oh-current-closed:not(.element-hidden){
  background-color:$green_light;
  border-radius:3px;
  padding:2px 5px;
  color:$highlight;
  display:inline-block;
  &:first-letter{
    text-transform:uppercase;
  }
}
.oh-current-closed{
  background-color:$red;
  color:white;
}

/* Section: Fuer Bewerber */
.section-fuer-bewerber{
  // Hide "Personalanfrage"-button from "Bewerber"-Section
  .btn--name-personalanfrage{
    display:none;
  }
  .btn--name-bewerben{
    @include btn-mod('highlight');
  }
}

.section-fuer-unternehmen{
  // Hide "Bewerben"-button from "Bewerber"-Section
  .btn--name-bewerben{
    display:none;
  }
}

/* Stellenangebot */
.pane-node-field-titelbild{
  margin-top:15px;
}
.node-type-stellenangebot{
  .page-panel-content{
    .field{
      margin-bottom:1em;
    }
  }
}

/* Contact Kontakt Page */
.section-kontakt .item--contact-form{
  display:none !important;
}

/* Online Bewerbung (Fuer Bewerber) */
// Zurueck Button in der linken Sidebar ausblenden, da ggf. verwirrend, sobald man sich auf der zweiten Formularseite befindet.
.page-node-34 .pane-custom.pane-1 [href*="history"]{
  display:none;
}

/* =============================================================================
 *   Tokens
 * ========================================================================== */

/*  Kontakt */
.contact-container .item-content {
    line-height: 20px;
    vertical-align: middle;
}
.contact-container .item {
    display: block;
    margin-bottom: 0.4em;
}
.contact-container br{ display:none; } /* BR is needed for HTML Mails */

/* =============================================================================
 *   Tables
 * ========================================================================== */

table.sticky-header {
  z-index: 10;
}

tr.odd {
  background: #fff;
}

tr.info,
tr.even,
tr:nth-child(2n+2) {
  border-bottom: 0;
  background-color: lighten($grey_light, 10%);
}

tr.odd td.active {
  background-color: lighten($grey_light, 5%);
}

tr.even td.active {
  background-color: lighten($grey_light, 2%);
}

/* =============================================================================
 *   Views styling
 * ========================================================================== */
/* Empty */
.view-empty{
  @include box-style;
  font-size: 0.9em;
  padding: 2em;
  text-align: center;
}

/* Groupings */
.views-group-title{
  // Group Titles
  @include grouping-title();
}

/* Filters */
.view-filters{
  clear:both;
  background:$grey_light;
}

/* Header & Footer */
.view-header{ margin-bottom:1em; }
.view-footer{ }

/* Row */
.views-row{
  margin:0 0 1em 0;
  padding:0 0 0.25em 0;
  @include clearfix-after;
}

/* -- Alternating Views Row Background */
.alternate-backgrounds .views-row{
  padding-right:10px;
  &.views-row-even{
    background:$grey_light;
    padding-top:0.5em;
    padding-bottom:0.5em;
    h3{
      margin-top:0;
    }
  }
}

/* Item List Stlyes */
.view .item-list:not(.list-style){
  > ul{
    margin:0;
    padding:0;
  }
  .views-row{
    list-style:none;
  }
  // Classic list style for view lists
  &.list-style li.views-row{
    list-style:square;
  }
}

/* Filter */
.views-exposed-widgets{
  margin:0;
}
.views-exposed-form{
  border-bottom:1px solid $grey;
  padding-bottom:0.35em;
  margin-bottom:1em;
  .description{
    margin-left:0;
  }
  .views-exposed-widget{
    float:none;
    padding:0.35em 1% 0.35em 1%;
    border-width:0 1px 1px 0;
    border-style: solid;
    border-color:$grey;
    .form-item{
      margin:0;
    }
  }
  .views-reset-button,
  .views-submit-button{
    clear:left;
    float:right;
    border:0 none;
    padding-top:5px;
    padding-bottom:0;
    text-align:right;
    .form-submit{
      margin:0;
    }
  }
  .views-reset-button{
    clear:none;
  }
  .form-item{
    margin-bottom:1em;
    .form-item{
      margin-bottom:0;
    }
    .form-text,
    .form-select{
      width:100%;
    }
    /* Fallback for js styled multiselect fields */
    select[multiple="multiple"]{
      height:70px;
      width:90%;
    }
  }
  // Hide submit button on auto submit filters
  .ctools-auto-submit-full-form .views-submit-button{
    display:none;
  }
}
@media only screen and (min-width: $tablet_portrait_end) {
  .views-exposed-form{
    .views-exposed-widget:not(.views-submit-button):not(.views-reset-button){
      float:left;
      width:25%;
      &:nth-child(2n){
        border-width:0 1px 1px 1px;
        margin-left:-1px;
      }
      &:nth-child(4n){
        border-right:0 none;
      }
      &:nth-child(4n+1){ clear:left; }
    }
  }
}

/* Read On Links */
.more-link,
.read-on{
  text-align:right;
  display:block;
  clear:both;
  a{
    @include btn('default', 'default', 'circle-arrow-right');
  }
}
td.read-on a{
  display:block;
  text-align:left;
  margin-bottom:0.25em;
}
.button-bar{
  clear:both;
  padding-top:$grid_vertical;
  text-align:right;
  > *{
    display:inline-block;
  }
}


/* Image */
.view-image{ margin: 0.5em 0; text-align:center; }

/* Glossar */
.view-id-glossary{
  .views-exposed-form{
    // Suche / Filter
    .views-submit-button{
      clear:none;
      float:left;
      margin-top:25px;
    }
  }
  .views-row{
    clear:both;
    margin-left: $grid / 2;
  }
  .attachment{
    margin-bottom:1.5em;
    color: $grey;
  }
  .views-summary{
    position:relative;
    a{
      text-decoration:none;
      padding:3px 5px;
      display:inline-block;
      min-width:15px;
      text-align:center;
    }
    .count{
      display:none;
    }
    &:hover{
      .count{
        position:absolute;
        bottom:17px;
        right:-2px;
        padding:2px;
        border-radius:$border_radius;
        font-size:0.65em;
        line-height:normal;
        display:inline;
      }
    }
    &:hover a,
    a.active{
      border-radius:$border_radius_small;
      margin-top:-3px;
      background:$highlight;
      color:#fff;
    }
    .view-content{
      a{
        display:inline-block;
        &:first-letter{
          font-size:1.2em;
          color:$highlight;
        }
      }
    }
  }
}

/* Standorte */
.view-id-ip_standorte{
  h3{
    font-weight:$font_weight_bold;
  }
}

/* Stellenangebote */
.view-ip-stellenangebote-block{
  h3{
    font-weight:$font_weight_bold;
  }
}
.view-ip-stellenangebote.view-display-id-panel_pane_1{
  .views-row{
    > .inner{
      border:1px solid $grey_light;
      padding:15px;
      transition-property: all;
      &:hover{
        background:$grey_light;

      }
    }
    h2{
      margin-top:0;
      font-weight:$font_weight_bold;
    }
  }
}
.views-field-field-stelle-branche,
.views-field-field-stelle-arbeitsort-umkreis{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

/* Mitarbeiter */
.view-ip-mitarbeiter{
  .item-list{
    > .views-group-title{
      // Grouping titles
      margin-left:$grid;
    }
  }
  .views-row{
    > .inner{
      border:1px solid $grey_light;
      padding:15px;
      transition-property: all;
      &:hover{
        background:$grey_light;
      }
    }
    h3{
      margin-top:0;
    }
  }
}

/* Standorte */
.view-display-id-standort_route_planner{
  .item-list{
    > .views-group-title{
      // Grouping titles
      margin-left:$grid;
    }
  }
  .views-row{
    > .inner{
      border:1px solid $grey_light;
      padding:15px;
      transition-property: all;
      &:hover{
        background:$grey_light;
      }
    }
    h2,
    h3{
      margin-top:0;
    }
  }
}
.views-field-field-oeffnungszeiten{
  margin-top:$grid_vertical;
}
.oh-current-open,
.oh-current-closed{
  margin-top:5px;
}

/* Mitarbeiter */
.views-field-field-xing-account{
  a{
    display:block;
    width:auto;
    height:40px;
    margin-top:8px;
    margin-bottom:8px;
    text-decoration:none;
    text-indent:-5000px;
    &::before{
      @include get-ico-only('xing');
      font-family:$iconfont;
      color:#126567;
      line-height:1em;
      font-size:40px;
      text-indent:0;
      float:left;
    }
  }
}

/* Pressestimmen */
.view-ip-pressestimmen{
  .views-row{
    margin-bottom:3px;
  }
}

/* FAQ aka Begriffe aus der Zeitarbeit */
.view-id-ip_faq{
  .views-row{
    margin:0 0 3px 0;
    h2{
      font-size:$font_normal;
      margin:0;
    }
  }
}

#backtotop{
  background-color: none !important;
}

/* Getdirections Print Bugfix */
// Wir muessen das in der Screen Darstellung ebenfalls auf die DinA4 Breite berechnen, da die Print Darstellung sonst falsch ermittelt wird.
.section-getdirections,
.getdirections{
  .td.getdirections-map,
  .getdirections-map > *{
    width:500pt !important;
    height:300pt !important;
  }
}

@import "modules/eu_cookie_compliance";
@import "modules/mytube_embed_video_cookie_compliance";
