// EU Cookie Compliance Message (D7)

// !! Ensure to copy the Javascript from fixes.custom.js also!

$eucc-small-breakpoint: 544px;
$eucc-medium-breakpoint: 768px;
$eucc-large-breakpoint: 992px;
$global-margin: 1rem !default;
$font-weight-normal: normal !default;
$brand-success: #409800 !default;
$small-font-size: 12px !default;
$medium-font-size: 14px !default;
$space-medium-h: 1rem !default;
$gray-dark: $grey_brown_dark !default;
$primary-color: $highlight !default;
$primary-color-text: white !default;
$grid-gutter: $grid !default;
#sliding-popup{
  position:fixed;
  z-index:9999999999; // Ensure the cookie message overlays the live chat.. and the other 'important' widgedts with senseless high z-index
  left:0;
  width:100% !important;
  background:$grey_brown_dark !important;
  color:$white;
  padding:15px 10px;
  font-size:$medium-font-size;
  transition-duration:100ms;
  transition-property: transform;
  box-sizing: border-box;
  *{
    box-sizing:border-box;
  }
  .popup-content{
    background:none;
    color: $white;
    border: 0 none;
    max-width:$page-max-width;
    margin:0 auto;
    background:none;
    &__inner{
      display:flex;
      justify-content: space-between;
      align-items:center;
      flex-flow:row wrap;
      margin-left: -1rem;
      margin-right: -1rem;
      @media screen and (min-width: #{$eucc-large-breakpoint}) {
        flex-flow: nowrap;
      }
      > *{
        padding-left: 1rem;
        padding-right:1rem;
      }
    }
    @media screen and (min-width: #{$eucc-medium-breakpoint}) {
      flex-wrap:nowrap;
    }
    &::before{
      display:none;
    }
    #popup-text{
      margin-bottom:$global-margin;
      min-width:300px;
      max-width:100vw;
      @media screen and (min-width: #{$eucc-medium-breakpoint}) {
        padding-right:$space-medium-h;
        margin-bottom:0;
      }
      h2,
      h3{
        font-size:1em;
        margin:0 0 .5em 0;
      }
      a{
        color:$highlight-text-color;
      }
      p{
        font-size:1em;
        font-weight:$font-weight-normal;
        color:$white !important;
        display:inline;
        margin:0;
      }
      .find-more-button{
        background:none;
        border:0 none;
        padding:0;
        text-decoration:underline;
        font-size: 1em !important;
        cursor:pointer;
        color:currentColor !important;
        vertical-align:baseline;
        &:hover{
          color: currentColor;
          text-decoration:underline;
        }
      }
    }
    .eu-cookie-compliance-categories{
      min-width:280px;
    }
    .eu-cookie-compliance-category{
      margin-top:.65em;
      margin-bottom:.65em;
      &__option{
        display:flex;
        align-items:start;
        input[type="checkbox"]{
          flex: 0 0 auto;
          margin: 0 .15rem 0 0 !important;
          &[disabled]{
            opacity:.75;
          }
          &:checked:not([disabled]){
            filter: $brand-success;
            + label{
              color: $brand-success;
            }
          }
        }
        label{
          flex: 1 1 auto;
          padding:0 !important;
          margin:0 !important;
          line-height:1.1;
        }
      }
      &:not(:last-child){
        margin-bottom:.35em;
      }
    }
    .eu-cookie-compliance-category-description{
      font-size: $small-font-size;
      line-height: 1.15;
      opacity:.65;
    }
    #popup-buttons{
      display:flex;
      flex-direction:column;
      @media screen and (max-width: #{$eucc-small-breakpoint - 1px}) {
        flex:1;
      }
      button{
        margin-bottom:2px !important;
        margin-left:2px;
        white-space:nowrap;
        &:not(.agree-button){
          // @include button(false, $light-color, $success-color, $light-color, hollow);
          background: transparent !important;
          border: 1px solid rgba(255,255,255,.25) !important;
        }
        &.agree-button{
          // @include button(false, $button-background, $button-background-hover, $white, solid);
          background-color: $primary-color !important;
          border-color: $primary-color !important;
          color: $primary-color-text !important;
          font-weight: bold !important;
          margin-bottom:.5rem !important;
          &:hover{
            background-color: lighten($primary-color, 5%) !important;
            color: $primary-color-text !important;
          }
          &:active,
          &:focus{
            background-color: darken($primary-color, 5%) !important;
            color: $primary-color-text !important;
          }
        }
        // &:not(:first-child){
        //   @media #{$medium-up}{
        //     margin-left:5px;
        //   }
        // }
      }
    }
    // Show buttons vertical if cookie categories present
    .eu-cookie-compliance-categories + #popup-buttons{
      display:block;
      .button{
        display:block;
        width:100% !important;
        margin-top:.2em !important;
        margin-bottom:.2em !important;
        text-align:center !important;
        font-size: $medium-font-size !important;
        &:not(.agree-button){
          padding-top:.35em !important;
          padding-bottom:.35em !important;
        }
        &.agree-button{
          font-weight: $font-weight-bold !important;
        }
      }
    }
  }
  // Revoke bar / button
  .eu-cookie-withdraw-tab{
    position: absolute;
    z-index:9999;
    font-size: 1em;
    padding:.75em;
    text-align:center;
    text-align: center;
    display: block;
    width: 100%;
    left: 0;
    top:0;
    white-space:nowrap;
    transform: translate(0,-100%);
    background-color: $primary-color;
    color: $primary-color-text;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    cursor:pointer;
    &__edit-label{
      display:none;
      margin-left:.25em;
      &::before{
        content: "\0028";
      }
      &::after{
        content: "\0029";
      }
    }
    @media screen and (min-width: #{$eucc-small-breakpoint}) {
      left: $grid-gutter;
      width:auto;
      display:inline;
    }
  }
  &.eu-cookie-withdraw-wrapper{
    padding-top:0;
    padding-bottom:0;
    .eu-cookie-withdraw-banner{
      background: none;
      padding-top:15px;
      padding-bottom:15px;
    }
    .popup-content{
      display:flex;
      align-items:center;
      justify-content:center;
      flex-flow:row wrap;
      > *{
        padding-left:1rem;
        padding-right:1rem;
      }
    }
  }
  // Modifier classes from the module stylesheets
  .eu-cookie-compliance-hidden {
    display: none !important;
  }
}


// Privacy page widget
.block-euccx-euccx-cookie-settings{
  position: relative;
  #cookie-tabs{
    display:flex;
    flex-direction: column;
    padding:0;
    @media screen and (min-width: #{$eucc-medium-breakpoint}) {
      flex-direction: row;
    }
    .ui-tabs-nav{
      width:auto;
      flex: 0 0 30%;
      margin:0;
      padding-bottom:3px;
      background: WhiteSmoke;
      @media screen and (min-width: #{$eucc-medium-breakpoint}) {
        padding-bottom:0;
      }
      > li{
        > a{
          width:100%;
          border:0 none;
          background-color: WhiteSmoke;
          color: $text-color;
          margin-bottom:0 !important;
        }
        &.ui-state-active{
          > a{
            background-color: $white;
            font-weight: bold;
          }
        }
      }
      &::after{
        display:none;
      }
    }
    .ui-tabs-panel{
      position:relative; // Position base for euccx-switch
      width:auto;
      flex: 0 0 70%;
      padding:40px 2rem 2rem 2rem; // padding-top should be minimum 34px to have reserved space for the euccx-switch
      h2,
      h3{
        font-weight: bold;
        margin-bottom:.5em;
      }
    }
  }
}

// Custom wrapper added by webksdct.eucc.js
.eucc-outer{
  overflow:hidden;
  @media #{$large-up}{
    position:fixed !important;
    bottom:0;
    left:0;
    width:100%;
  }
}

// Ensure the footer menu legal links are still visible (imprint, ...)
body[class*="eu-cookie-compliance"]{
  &.eu-cookie-compliance-popup-open{
    padding-bottom:var(--eucc-banner-height, 100vh) !important;
    @media screen and (min-width: #{$eucc-large-breakpoint}) {
      padding-bottom:var(--eucc-banner-height, 300px) !important;
    }
  }
  &:not(.eu-cookie-compliance-popup-open){
    .eucc-outer{
      height:var(--eucc-withdraw-tab-height, 50px);
      position: relative;
      #sliding-popup{
        // change position base for the widthdraw tab from #sliding-popup to .eucc-outer
        position:static;
        background:transparent !important;
        .eu-cookie-withdraw-tab{
          transform: none;
        }
        .popup-content{
          opacity:0;
        }
      }
    }
  }
}
